import { useTranslation } from "react-i18next";
import GooglePlayIcon from "../../assets/images/google-play-logo.png";
import AppleIcon from "../../assets/images/apple-logo.png";
import "./StoresBtn.scss";

export const StoresBtn = () => {
  const { t } = useTranslation();

  return (
    <div className="stores-container">
      <a
        href="https://play.google.com/store/apps/details?id=com.bdouin.awladschool&hl=fr&gl=US"
        target="_blank"
        className="stores-container__link stores-container__link--google"
        rel="noreferrer"
      >
        <img src={GooglePlayIcon} alt="" />
        <p>
          {t("available")} <br />
          <strong>Google Play</strong>
        </p>
      </a>
      <a
        href="https://apps.apple.com/tt/app/awlad-learn-arabic/id1612014910"
        target="_blank"
        className="stores-container__link stores-container__link--apple"
        rel="noreferrer"
      >
        <img src={AppleIcon} alt="" />
        <p>
          {t("available")} <br />
          <strong>App Store</strong>
        </p>
      </a>
    </div>
  );
};
