import { useEffect } from "react";
import { useLocation } from "react-router";

export const ScrollToTop = (props: any) => {
  const location = useLocation();
  useEffect(() => {
    if (location.hash === '#contest' && document.getElementById("contest")) {
      document.getElementById("contest")?.scrollIntoView();
    } else {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return <>{props.children}</>
};

