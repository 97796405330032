import { Player } from "@lottiefiles/react-lottie-player"
import DrizzleAnimation from "../../assets/lotties/drizzle.json"
import "./RainLogo.scss"

type RainLogoProps =  {
  className?: string
}

export const RainLogo = ({className}: RainLogoProps) => {
  return (
    <div className={`${className || ''} container`}>
      <Player src={DrizzleAnimation} className="container__logo" autoplay loop />
    </div>
  )
}