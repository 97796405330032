import { useTranslation } from "react-i18next";
import "./VideoPresentation.scss";
import BoatPaperAnimation from "../assets/lotties/boat-paper.json";
import WavesAnimation from "../assets/lotties/waves.json"
import { Player } from "@lottiefiles/react-lottie-player";
import { Button } from "../components/ui/Button";

export const VideoPresentation = () => {
  const { t } = useTranslation();

  return (
    <section className="video-presentation">
      <div className="video-presentation__container">
        <div className="video-presentation__title">
          <h2>
            {t("awlad-cartoon")}
          </h2>
          <h2> {t('animation-project')}</h2>
        </div>
        <div className="video-presentation__vimeo">
          <iframe
            src={'https://www.youtube.com/embed/GNwyV6jncNQ?si=HQNMxEIceaXfBts5'}
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            width="100%"
            height="100%"
            title="AWLAD School Pr&amp;eacute;sentation"
          ></iframe>
        </div>
        <Button link="https://www.youtube.com/channel/UCevtC6rBI9NEck90DOvywlQ" mobileTheme="tertiary" desktopTheme="primary" className="video-presentation__cta">
          {t("access-youtube-channel")}
        </Button>
      </div>
      <div className="video-presentation__awlad-boat">
        <div className="video-presentation__awlad-boat--boat">
          <Player autoplay loop src={BoatPaperAnimation} />
        </div>
        <div className="video-presentation__awlad-boat--waves">
          <Player autoplay loop src={WavesAnimation} />
        </div>
      </div>
    </section>
  );
};
