import { Trans, useTranslation } from "react-i18next";
import FoulaneFamily from "../assets/images/foulane-family.svg"
import "./Foulane.scss"
import { Button } from "../components/ui/Button";

export const Foulane = () => {

  const { t } = useTranslation();

  return (
    <section className="foulane" id={t('foulane-family-menu')}>
      <div
        aria-hidden="true"
        className="foulane__illustration"
      />
      <h2 className="foulane__title">{t('foulane-family')}</h2>
      <div className="foulane__card">
        <h3 className="foulane__card--title">
          <Trans i18nKey={t('foulane-characters')} />
        </h3>
        <img className="foulane__card--illustration" src={FoulaneFamily} alt={t('foulane-characters')} />
        <p className="foulane__card--desc">
          <Trans i18nKey={t('foulane-desc')} />
        </p>
        <Button link="https://www.bdouin.com/13-famille-foulane" target="_blank" theme="primary" className="foulane__card--cta">{t('buy')}</Button>
      </div>
    </section>
  )
}