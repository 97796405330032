import NoredineIllustration from '../assets/images/noredine-illustration.svg'
import EthiqueIllustration from '../assets/images/ethique-illustration.svg'
import UserWorldIllustration from '../assets/images/user-world-illustration.svg'
import { Trans, useTranslation } from 'react-i18next'
import React, { useState } from 'react';
import "./About.scss"
import { Button } from '../components/ui/Button';
import { ReactComponent as Map } from '../assets/images/map.svg';
import { ReactComponent as IconClose } from '../assets/images/icon-close.svg';
import Modal from 'react-modal';

Modal.setAppElement(document.body);

interface AboutCardProps {
  title: string;
  description: React.ReactNode;
  image: string;
  index: number;
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const AboutCard: React.FC<AboutCardProps> = ({ title, description, image, index }) => {
  return (
    <div className={`about-card about-card__card-${index}`}>
      <h2 className="about-card__title">{title}</h2>
      <div className='about-card__img-container'>
        <img src={image} alt={title} className="about-card_illustration" />
      </div>
      <p className="about-card__description">{description}</p>
    </div>
  );
};


export const About: React.FC = () => {

  const { t } = useTranslation()
  const [openMap, setOpenMap] = useState<boolean>(false);

  const data = [
    {
      title: t('who-are-we'),
      description: <Trans i18nKey={t('about-us')} />,
      image: NoredineIllustration,
    },
    {
      title: t('our-ethics'),
      description: <Trans i18nKey={t('ethics-desc')} />,
      image: EthiqueIllustration,
    },
    {
      title: t('our-readers'),
      description: <Trans i18nKey={t('readers-desc')} />,
      image: UserWorldIllustration,
    },
  ];

  const toggleModalMap = () => {
    setOpenMap(!openMap);
    if (!document.body.classList.contains("blockScrolling")) {
      document.body.classList.add("blockScrolling");
    } else {
      document.body.classList.remove("blockScrolling");
    }
  }

  return (<>
    <Modal
      isOpen={openMap}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <IconClose className='close-btn' onClick={toggleModalMap} />
      <Map className='world-map' />
    </Modal>
    <section className="about">
      <div className='about__card-container'>
        {data.map((item, index) => (
          <AboutCard key={index} index={index} {...item} />
        ))}
      </div>
      <Button onClick={toggleModalMap} theme='primary' className='about__cta'>
        {t('subscriber-map')}
      </Button>
    </section>
  </>);
};