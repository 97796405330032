import { About } from "../sections/About"
import { BuyBook } from "../sections/BuyBook"
import { Foulane } from "../sections/Foulane"
import { HeroSection } from "../sections/HeroSection"
import { Hiring } from "../sections/Hiring"
import { MuslimShow } from "../sections/MuslimShow"
import { VideoPresentation } from "../sections/VideoPresentation"


export const Homepage = () => {
  return (
    <>
      <HeroSection />
      <About />
      <MuslimShow />
      <Foulane />
      <BuyBook />
      <VideoPresentation />
      <Hiring />
    </>
  )
}