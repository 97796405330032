import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './App.css';
import { ScrollToTop } from "./components/ScrollToTop";
import { Layout } from "./layout/Layout";
import { Homepage } from "./pages/Homepage";


function App() {
  return (
    <Router >
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomepageRender/>} />
      </Routes>
    </Router>
  );
}

const HomepageRender = (props: any) => (
  <Layout key="homepage-page" className="homepage-page">
    <Homepage {...props} />
  </Layout>
);

export default App;
