import React from 'react';
import './Button.scss';

type Theme = 'primary' | 'secondary' | 'tertiary';

type ButtonProps = (React.ButtonHTMLAttributes<HTMLButtonElement> & React.AnchorHTMLAttributes<HTMLAnchorElement>) & {
  theme?: Theme;
  desktopTheme?: Theme;
  mobileTheme?: Theme;
  children: React.ReactNode;
  link?: string;
};

export const Button: React.FC<ButtonProps> = ({ theme = 'primary', desktopTheme, mobileTheme, children, link, className, ...props }) => {
  let buttonClass = `button ${theme}`;

  if (desktopTheme && mobileTheme) {
    buttonClass = `button desktop-${desktopTheme} mobile-${mobileTheme}`;
  }

  if (className) {
    buttonClass += ` ${className}`;
  }

  if (link) {
    return (
      <a href={link} className={buttonClass} {...props}>
        {children}
      </a>
    );
  }

  return <button className={buttonClass} {...props}>{children}</button>;
};