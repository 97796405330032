import { Trans, useTranslation } from "react-i18next";
import "./MuslimShow.scss";
import { ReactComponent as InstagramLogo } from "../assets/images/instagram-logo.svg";
import { ReactComponent as FacebookLogo } from "../assets/images/facebook-logo.svg";
import { Button } from "../components/ui/Button";


export const MuslimShow = () => {

  const { t } = useTranslation()

  return (
    <section id={t('muslim-show')} className="muslim-show">
      <div
        aria-hidden="true"
        className="muslim-show__illustration"
      />
      <div
        aria-hidden="true"
        className="muslim-show__moon-illustration"
      />
      <h3 className="muslim-show__title"><span>{t('the')}</span>{t('muslim-show')}</h3>
      <div className="muslim-show__card">
        <p className="muslim-show__card--desc">
          <Trans i18nKey={t('muslim-show-desc')} />
        </p>
        <div className="muslim-show__card--social">
          <a href="https://facebook.com/MuslimShow" target="_blank" rel="noreferrer">
            <FacebookLogo />
          </a>
          <a href="https://www.instagram.com/muslimshowofficiel/" target="_blank" rel="noreferrer">
            <InstagramLogo />
          </a>
        </div>
        <Button link="https://www.bdouin.com/18-muslim-show" target="_blank" theme="primary" className="muslim-show__card--cta">{t('buy')}</Button>
      </div>
    </section>
  )
}