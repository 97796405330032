import { Player } from "@lottiefiles/react-lottie-player";
import "./Hiring.scss";
import SubAnimation from "../assets/lotties/sub-02.json"
import { Trans, useTranslation } from "react-i18next";
import { RainLogo } from "../components/ui/RainLogo";
import { ReactComponent as Logo } from '../assets/images/bdouin-studio-logo.svg';


export const Hiring = () => {

  const { t } = useTranslation();

  return (
    <section className="hiring" id={t('we-recruit')}>
      <h3 className="hiring__title">{t("we-recruit")}</h3>
      <p className="hiring__desc"><Trans i18nKey={t('recruit-desc')} /></p>
      <a href="mailto:syukr@awladcompany.com" className="hiring__cta">{t('recruit-submit')}</a>
      <p className="hiring__details">{t('full-remote')}</p>
      <Player src={SubAnimation} loop autoplay className="hiring__sub-animation" />
      <div className="hiring__logo-container">
        <RainLogo />
        <Logo className="hiring__logo-container--logo" />
      </div>
    </section>
  )
}