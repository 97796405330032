import { Trans, useTranslation } from "react-i18next";
import './BuyBook.scss';
import BookMobile from '../assets/images/walad-binti-book-mobile.svg';
import BookDesktop from '../assets/images/walad-binti-book-desktop.svg';
import { Button } from "../components/ui/Button";

export const BuyBook = () => {
  const { t } = useTranslation();
  return (
    <section className="buy-book">
      <div
        aria-hidden="true"
        className="buy-book__illustration"
      />
      <div
        aria-hidden="true"
        className="buy-book__walad-binti-illustration"
      />
      <div className="buy-book__container">
        <div className="buy-book__content">
          <h3 className="buy-book__content--title">
            <Trans i18nKey={t('bd-availability')} />
          </h3>
          <p className="buy-book__content--desc">
            <Trans i18nKey={t('bd-desc')} />
          </p>
          <Button link="https://www.bdouin.com/accueil/175-walad-et-binti-le-bien-gagne-toujours-par-bdouin-et-la-team-muslim-show-9789998770508.html" target="_blank" theme="primary" className="buy-book__content--cta">
            {t('buy')}
          </Button>
        </div>
        <img src={BookMobile} alt="walad & binti" className="buy-book__book-illustration buy-book__book-illustration--mobile" />
        <img src={BookDesktop} alt="walad & binti" className="buy-book__book-illustration buy-book__book-illustration--desktop" />
      </div>
    </section>
  )
}