import './Header.scss';
import { useTranslation } from "react-i18next"
import { ReactComponent as Logo } from '../assets/images/bdouin-studio-logo.svg';
import { ReactComponent as LeftArrow } from '../assets/images/left-arrow.svg';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

export const Header = (props: { className?: string }) => {
  const { className } = props;
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState<string>();
  const [isMenuOpen, setMenuIsOpen] = useState<boolean>();
  const [activeLink, setActiveLink] = useState<string>('homepage');

  const changeLanguageHandler = (event: any) => {
    i18n.changeLanguage(event.target.value.toLowerCase());
    setLanguage(event.target.value.toLowerCase())
  }
  useEffect(() => {
    setLanguage(i18n.resolvedLanguage)
  }, [i18n.resolvedLanguage]);
  return (
    <>
      <header className={className || ''}>
        <nav>
          <div className="burger-wrapper">
            <div className={(isMenuOpen && "open ") + " burger burger-rotate"} onClick={() => setMenuIsOpen(prev => !prev)}>
              <div className="burger-lines"></div>
            </div>
          </div>
          <Link to='/' className='logo' onClick={() => { setActiveLink('homepage'); setMenuIsOpen(false); }}><Logo /></Link>
          <div className={(isMenuOpen && "open ") + ' links'}>
            <Link to='/' className={`homepage ${activeLink === 'homepage' ? 'active' : ''}`} onClick={() => { setActiveLink('homepage'); setMenuIsOpen(false); }}>{t('presentation')}</Link>
            <a href={`/#${t('muslim-show')}`} className={`muslimshow ${activeLink === 'muslimshow' ? 'active' : ''}`} onClick={() => { setActiveLink('muslimshow'); setMenuIsOpen(false); }}>{t('muslim-show')}</a>
            <a href={`/#${t('foulane-family-menu')}`} className={`foulanefamily ${activeLink === 'foulanefamily' ? 'active' : ''}`} onClick={() => { setActiveLink('foulanefamily'); setMenuIsOpen(false); }}>{t('foulane-family-menu')}</a>
            <a href={`/#${t('we-recruit')}`} className={`werecruit ${activeLink === 'werecruit' ? 'active' : ''}`} onClick={() => { setActiveLink('werecruit'); setMenuIsOpen(false); }}>{t('we-recruit')}</a>
          </div>
          <div className='switch-language'>
            <select onChange={changeLanguageHandler} value={language}>
              <option value={'fr'}>FR</option>
              <option value={'en'}>EN</option>
            </select>
            <LeftArrow />
          </div>
        </nav>
      </header>
    </>
  );
}