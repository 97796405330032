import { Player } from "@lottiefiles/react-lottie-player";
import { useTranslation } from "react-i18next";
import { ReactComponent as Logo } from '../assets/images/bdouin-studio-logo.svg';
import HeartAnimation from "../assets/lotties/heart.json"
import ReadAnimation from "../assets/lotties/read.json"
import "./HeroSection.scss";
import { StoresBtn } from "../components/ui/StoresBtn";
import { RainLogo } from "../components/ui/RainLogo";
import { Button } from "../components/ui/Button";

export const HeroSection = () => {
  const { t } = useTranslation();

  return (
    <div className="hero-section">
      <div
        aria-hidden="true"
        className="hero-section__illustration"
      />
      <div className="hero-section__content">
        <div>
          <RainLogo className="hero-section__content--rain-animation" />
          <Logo className="hero-section__content--logo" />
        </div>
        <h1 className="hero-section__content--slogan">{t('hero-slogan')}</h1>
        <p className="hero-section__content--desc">{t('hero-desc')}</p>
        <div className="hero-section__info hero-section__info--desktop">
          <Button desktopTheme="primary" mobileTheme="secondary">{t("access-app")}           
            <Player
              autoplay
              loop
              src={HeartAnimation}
              className="hero-section__heart-animation"
            />
          </Button>
          <StoresBtn />
          <Button link="https://www.bdouin.com/" target="_blank" desktopTheme="primary" mobileTheme="secondary" className="hero-section__info--cta">{t("access-shop")}           
            <Player
              autoplay
              loop
              src={ReadAnimation}
              className="hero-section__read-animation"
            />
          </Button>
        </div>
      </div>
      <div className="hero-section__info hero-section__info--mobile">
        <Button desktopTheme="primary" mobileTheme="secondary" className="hero-section__info--cta">{t("access-app")}           
          <Player
            autoplay
            loop
            src={HeartAnimation}
            className="hero-section__heart-animation"
          />
        </Button>
        <Button link="https://www.bdouin.com/" target="_blank" desktopTheme="primary" mobileTheme="secondary" className="hero-section__info--cta">{t("access-shop")}           
          <Player
            autoplay
            loop
            src={ReadAnimation}
            className="hero-section__read-animation"
          />
        </Button>
      </div>
    </div>
  );
};
