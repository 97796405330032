import "./Footer.scss";
import { useTranslation } from "react-i18next";

export const Footer = (props: { className?: string }) => {
  const { t } = useTranslation();

  return (
    <footer className="copyright">
        &copy;{new Date().getFullYear()}. {t("footer-copyright")}
    </footer>
  );
}
